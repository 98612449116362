.footer{
    background-color:#121212;
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:space-between;
    min-height: 70px !important;
    border-radius:8px !important;
    padding:20px 25px !important;
}
.footer .footer_copyright{
    font-size:small !important;
    text-align: end !important;
    color: #9c9c9c;
}
.footer .footer_copyright > a {
    text-decoration: none !important;
    color: var(--main-color) !important;
}