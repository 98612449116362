.profile{
    background-color: #fff;
    border-radius:10px;
    width:100%;
    display: inline-block;    
}
.profile_name{
    line-height: 18px;
    padding: 20px;
}
.profile_name .name{
    text-transform: uppercase;
    font-size: 17px;
    font-weight:bold;
}
.profile_name .title{
    font-size:14px;
    color: #757376;
}
.profile_image{
    margin-top:-10px;
    -webkit-clip-path: polygon(0 9%, 100% 0 , 100% 94% , 0% 100%);
    clip-path: polygon(0 9%, 100% 0, 100% 94% , 0% 100%);
}
.profile_image img{
    width:100%;
}
.profile_information{
    top: 0 !important;
    left: 0 !important;
    margin-top: 12px !important;
    margin: -40px 10px 30px !important;
}
.profile_information .timeline .timeline_content{
    margin-top: 1px !important;
    margin-bottom: 12px !important;
}
.timelineItem_text{
    font-size:13.5px !important;
    color: grey !important;
    font-weight:400 !important;
    
}

.timelineItem_text > span{
    color: black;
    
}

.profile .button_container{
    width:100% !important;
    display:flex !important;
    justify-content:center !important;
    margin-top:10px !important;
}
