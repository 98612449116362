:root{
    --main-color:#ffc500;
    --dot-color:#fff;
}
.container_shadow{
    box-shadow: 0px 2px 92px 0px rgba(0,0,0,0.13);
    -webkit-box-shadow: 0px 2px 92px 0px rgba(0,0,0,0.13);
    -moz-box-shadow: 0px 2px 92px 0px rgba(0,0,0,0.13);
}
.top_60{
    margin-top: 60px !important;
}
.top_30{
    margin-top: 30px !important;
}
.mb_20{
    margin-top: 30px !important;
}
.p_30{
    padding: 30px !important;
}
.pt_45{
    padding-top: 45px !important;
}
.pb_45{
    padding-bottom: 45px !important;
}
.p_50{
    padding: 50 !important;
}
@media (max-width:991px){
    .top_60{
        margin-top: 30px !important;
    }
}
.main-content{
    background-color:white !important;
    min-height: 100vh !important;
    border-radius:6px !important;
    margin-bottom: 30px !important;
    /* padding:30px !important; */
}
.section_title{
    position:relative;
    display:inline-block;
    padding: 0 3px !important;
}
.section_title .section_title_text {
    font-size:18px;
    font-weight: 600;
    position: relative;
    margin: 0;
}
.section_title span {
    position: absolute;
    height:7px;
    width:100%;
    bottom: 0;
    opacity: 0.7;
    left: 0;
    background-color:var(--main-color);
}
.greybg{
    background-color: #fafafa !important;   
}
