.contact-form > button {
  
  border: none !important;
  background-color: #ffc500;
  font-weight: 500 !important;
  font-size: 15px !important;
  border-radius: 50px !important;
  color: white !important;
  cursor: pointer !important;
  transition: 0.2s ease-in-out !important;
  margin-top: 25px !important;
  width: 120px !important;
  margin-left: 30px !important;
  padding: 10px !important;
  
}
.header-container-item{
  display: inline-block !important;
}

.heading-contact{
  display: inline-block !important;
}

.heading-contact>h6{
  margin-top:10px !important;
  font-size: 17px !important;
  
}

.contact-form > button:hover {
  background-color: #ffc500;
}

.heading-contact{
  position:relative;
  display:inline-block;
  padding: 0 3px !important;
}

.heading-contact span {
  position: absolute;
  height: 3px;
  width: 100%;
  margin-bottom:4px !important;
  bottom: 0;
  opacity: 0.7;
  left: 0;
  background-color: var(--main-color);
}

.heading-contact{
  margin-left: 30px !important;
  margin-top: 10px !important;
}

input{
  border: 0;
  border-bottom: solid 1px #dfdfdf!important;
  width:350px !important;
  margin-left: 30px !important;
}

.input-message{
  margin-top: 25px !important;
  width: 350px !important;
}



.section_title-contact{
  position:relative;
  display:inline-block;
  margin-left: 30px !important;
  margin-bottom: 10px !important;
  display: inline-block !important;

}

.section_title-contact .section_title_text-contact {
  font-size:18px;
  font-weight: 600;
  position: relative;
  margin: 0;
}
.section_title-contact span {
  position: absolute;
  height:3px;
  width:100%;
  bottom: 0;
  opacity: 0.7;
  left: 0;
  background-color:var(--main-color);
}
.section-contact .section_topo_text .contact_text {
  margin-top:10px !important;
  font-weight: 300% !important;
  margin-left: 30px !important;

}

.contact-form > .MuiGrid-container{
  margin: top 50px !important;
}

.Map-Item-head{
  width:500px !important;
  height:300px !important;
}



@media (max-width:1300px){
  input{
    width:250px !important;
  }
  .input-message{
    width: 250px  !important;
  }
}

@media (max-width:955px){
  input{
    width:500px !important;
  }
  .input-message{
    width: 500px  !important;
  }
  .timeline .timeline_dots_header{
    /* align-items: center !important; */
    margin-left: 200px !important;
  }
  .contact-form > button{
    margin-left: 200px !important;
    
  }
  .heading-contact{
    margin-left: 200px !important;
  }

}


@media (max-width:710px){
  .timeline .timeline_dots_header{
    /* align-items: center !important; */
    margin-left: 150px !important;
  }
}

@media (max-width:610px){
  .timeline .timeline_dots_header{
    /* align-items: center !important; */
    margin-left: 50px !important;
  }
}

@media (max-width:590px){
  .heading-contact{
    margin-left:100px !important;
  }

  .contact-form > button{
    margin-left:100px !important;

  }

  .section_title_text-contact{
    margin-left:20px !important;

  }

  .section_title-contact span{
    width: 200px  !important;
    margin-left:20px !important;
  }

  input{
    width:350px !important;
  }
  .input-message{
    width: 350px  !important;
  }
}


@media (max-width:442px){
  input{
    width:250px !important;
  }
  .input-message{
    width:250px  !important;
  }
  .timeline .timeline_dots_header{
    /* align-items: center !important; */
    margin-left: 0px !important;
  }
}

@media (max-width:360px){
  input{
    width:200px !important;
  }
  .input-message{
    width:200px  !important;
  }
  .timeline .timeline_dots_header{
    /* align-items: center !important; */
    margin-left: 0px !important;
  }
  .heading-contact{
    margin-left:0px !important;
  }

  .contact-form > button{
    margin-left:0px !important;

  }
}

.map-sect .map-frame{
  margin-left: 30px ;
  border: solid 3px #000000!important;
  width: 850px ;
  height:450px ;
  margin-top: 20px !important;
}

@media (max-width:1280px){
  iframe {
    align-items: center !important;
    width: 580px !important;
    height:300px !important;
  }
}



@media (max-width:1000px){
  iframe {
    align-items: center !important;
    width: 550px !important;
    height:300px !important;
  }
}


@media (max-width:959px){
  iframe {
    align-items: center !important;
    width: 750px !important;
    height:300px !important;
  }
}

@media (max-width:835px){
  iframe {
    align-items: center !important;
    width: 600px !important;
    height:300px !important;
  }
}

@media (max-width:680px){
  iframe {
    align-items: center !important;
    width: 450px !important;
    height:300px !important;
  }
}

@media (max-width:515px){
  iframe {
    align-items: center !important;
    width: 300px !important;
    height:200px !important;
  }
}

@media (max-width:365px){
  iframe {
    align-items: center !important;
    width: 250px !important;
    height:200px !important;
  }
}

@media (max-width:315px){
  iframe {
    align-items: center !important;
    width: 200px !important;
    height:180px !important;
  }
}