.timeline{
  padding:0 !important;   
}
.timeline .timeline_header{
    padding-top: 7px !important;
}
.timeline .timeline_firstItem{
    min-height: 88px !important;
}
.timeline .MuiTimelineItem-root{
    min-height:0px !important;
}
.timeline .MuiTimelineItem-missingOppositeContent:before{
    display: none !important;
}
.timeline .timeline_dots_header{
    color: black;
    background-color: var(--main-color);
    font-size: small !important;
    padding:10px !important;
}

.timeline .timeline_dot{
    color: black;
    border-color: var(--main-color);    
    padding:2px !important;
}
.timeline .MuiTimelineConnector-root{
    background-color: #eee!important;
    
}
.timeline .timeline_dots_header > .MuiSvgIcon-root {
    font-size: 20px !important;
    color: black !important;
}
.timeline .separator_padding{
    padding-left:18px !important;    
}
.timeline .timeline_content{
    padding-top: 0px !important;
    margin-bottom: 20px !important;
}

