.header{
    margin-bottom: 30px !important;
    background-color: white !important;
    padding:0 22px 0 0 !important;
    border-radius:6px !important;
    box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13) !important;
    
}
.header .header_navlink{
    padding:0 !important;
}
.header .header_link{
    font-weight: bold !important;
    color: darkslategray !important;
}


.navbar-light .navbar-nav .nav-link:hover{
    color: var(--main-color) !important;
    
}
.navbar-light .navbar-nav .nav-link:focus{
    font-weight: 700 !important;    
    color: var(--main-color) !important;}

.header .header_home{
    background-color:var(--main-color);
    padding:22px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}
.header .header_left {
    margin-right: auto !important;
}
.header .header_right{
    display: flex !important;
    align-items: center !important;
}
.header .header_right > a > .MuiSvgIcon-root{
    font-size:16px !important;
    margin-right: 12px !important;
    color: black;
}

